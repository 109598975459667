import React from 'react';
import { Image, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import BindEnterAndSpaceToClick from '../../../core/Utils/BindEnterAndSpaceToClick';
import { DefaultProps, PropTypes } from '../../../core/Utils/SitecoreFields';

const SocialShare = ({ fields, sitecoreContext }) => {
  const socialShare = (href) => {
    window.open(
      href
        .replace('{fbID}', sitecoreContext.metadata.facebookAppId)
        .replace('{URL}', window.location.href),
      'social share',
      'width=600, height=350',
    );
  };

  const shareLinks = fields.shareLinks.map(({ fields, id }) => {
    return (
      <li key={id}>
        <span
          role="button"
          tabIndex="0"
          aria-label={fields.title.value}
          onClick={() => socialShare(fields.link.value.href)}
          onKeyUp={BindEnterAndSpaceToClick}
        >
          <Image field={fields.image} />
        </span>
      </li>
    );
  });

  return (
    <div className="social-share">
      <ul>{shareLinks}</ul>
    </div>
  );
};

SocialShare.propTypes = PropTypes.SitecoreItem.inject({
  shareLInks: PropTypes.Multilist.inject(PropTypes.ImageLink),
});

SocialShare.defaultProps = DefaultProps.SitecoreItem.inject({
  shareLinks: DefaultProps.Multilist,
});

export default withSitecoreContext()(SocialShare);
